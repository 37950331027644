import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from "@angular/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import { HomeComponent } from './modules_/home/home.component';
import { CommonHeaderComponent } from './shared_/components_/common-header/common-header.component';
import { CommonFooterComponent } from './shared_/components_/common-footer/common-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppointmentComponent } from './modules_/appointment/appointment.component';
import { wrapperComponent } from './modules_/wrapper/wrapper.component';
import { SharedModule } from './shared_/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
// import { PaymentConfirmationComponent } from './modules_/payment-confirmation/payment-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    // CommonHeaderComponent,
    // CommonFooterComponent,
    // AppointmentComponent,
    wrapperComponent,
    // PaymentConfirmationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    SharedModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
