import { Component } from '@angular/core';
import { FrontService } from 'src/app/services_/front.services';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.css']
})

export class MaintenanceComponent {
    constructor(public frontService: FrontService) { }
}