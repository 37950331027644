import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonHeaderComponent } from './components_/common-header/common-header.component';
import { CommonFooterComponent } from './components_/common-footer/common-footer.component';
import { FrontService } from "../services_/front.services";
import { TimeFilterComponent } from "./time-filter/time-filter.component";
import { Daterangepicker } from "ng2-daterangepicker";
import { TimeFilterService } from "./time-filter/time-filter.service.component";
import { DragDropDirective } from './directives_/drag-drop.directive';
import { MaintenanceComponent } from './components_/maintenance/maintenance.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Daterangepicker
  ],
  declarations: [
  CommonHeaderComponent,
  TimeFilterComponent,
  CommonFooterComponent, 
  DragDropDirective,
  MaintenanceComponent
],
  exports: [
    ReactiveFormsModule,
    RouterModule,
    CommonHeaderComponent,
    TimeFilterComponent,
    DragDropDirective,
    CommonFooterComponent
  ],
  providers: [
    FrontService,
    TimeFilterService
  ],
  entryComponents:[CommonHeaderComponent,CommonFooterComponent]
})
export class SharedModule {}
