import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Router
} from "@angular/router";

import { environment } from '../../environments/environment';


@Injectable({ providedIn: "root" })
export class FrontService {
    constructor(
        public http: HttpClient,
        private router: Router,
    ) {

    }

    getdoctorlist(data) {
        console.log("data-->", data)
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/searchDoctors', data);
    }

    getdoctordetail(data) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/getdoctordetail', data);
    }

    savedoctorDetails(data) {
        sessionStorage.setItem("doctor", JSON.stringify(data));
    }

    addreview(data) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/addreview', data);
    }

    contact(data) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/contact', data);
    }

    getcliniclist() {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/getcliniclist', []);
    }

    getdoctor(data) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/getdoctor', data);
    }

    loadBookingSlots(data) {
        return this.http.post(
            environment._WEBGATEWAY_BASIC_URL_ + "api/Appointments/slots",
            data
        );
    }

    savePatientBookingAppoinment(name, contact, gender, dob, age, email, slots,
        appointmentDate, selectedDocUserMapping, selectedDocId) {
        let data = { name: name, phoneNO: contact, gender: gender, dob: dob, age: age, email: email, preferredTime: slots, checkedIn: 'false', appointmentDate: appointmentDate, spotWalkin: '0', selectedDocUserMapping: selectedDocUserMapping, selectedDocId: selectedDocId }
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/Appointments/addPatient', data);
    }

    getPaymentKey() {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + 'api/paymentKey');
    }

    generateOrderId(formData) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'payment/order', formData);
    }

    savePatientBookingAppoinmentViaRazorPay(formData) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'payment', formData);
    }

    getDocDetailsWithOrgNameAndDocName(orgName, docName) {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + 'api/Appointments/getDocDetails/' + orgName + '/' + docName);
    }

    getDocDetailsWithUserOrgAlias(userOrgAlias) {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + 'api/Appointments/getDocDetails/' + userOrgAlias);
    }

    getScheduleData() {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + 'api/Appointments/getScheduleData');
    }

    getDocsScheduleData(oumId) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'api/Appointments/getScheduleData', oumId);
    }

    updatePaymentConfirmation(encryptedUrl) {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + "payment/confirmation/" + encryptedUrl);
    }

    capturePayment(queryParams) {
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + "payment/confirmation" + queryParams, "");
    }

    uploadDocument(formData) {
        return this.http.post(
            environment._WEBGATEWAY_BASIC_URL_ + "teleconsultation/uploadDocument",
            formData
        );
    }

    getPatientDetails(roomId) {
        return this.http.get(environment._WEBGATEWAY_BASIC_URL_ + 'teleconsultation/' + roomId);
    }

    getPhoneNumber() {
        return environment._CONTACT_NO_;
    }

    getProfileImage(data){
        return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'Doctor/getProfileImage', data);
    }

    // getProfileImage(doctor_id) {
    //     return this.http.post(environment._WEBGATEWAY_BASIC_URL_ + 'Doctor/getProfileImage/' + doctor_id, "");
    // }

}
