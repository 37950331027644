import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wrapper',
    templateUrl: 'wrapper.component.html'
})

export class wrapperComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}