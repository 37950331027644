import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.css']
})
export class CommonFooterComponent implements OnInit {
  copyrightYear: string;

  constructor(private router: Router) {
    this.copyrightYear = environment._COPYRIGHT_YEAR;
  }

  ngOnInit() {
  }

  navigate(path) {
    this.router.navigate([path]);
  }



}
