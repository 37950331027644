import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css']
})
export class CommonHeaderComponent implements OnInit {
  currentpath :any;
  currentTab = "";
  _fg: FormGroup;
  constructor(private router: Router) { 
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.currentpath = val.url;
        var splitted = this.currentpath.split("/");
        this.currentTab = splitted[1];
      }
    });
    console.log(this.currentTab);
    this.initForm();
  }

  ngOnInit() {
  }

  initForm() {
    this._fg = new FormGroup({
      mobile: new FormControl("")
    });
  }

  navigate(path) {
    this.router.navigate([path]);
  }

}
