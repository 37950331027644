import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules_/home/home.component';
import { wrapperComponent } from './modules_/wrapper/wrapper.component';
import { AppointmentComponent } from './modules_/appointment/appointment.component';

const routes: Routes = [
  {
    path: "",
    component: wrapperComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./modules_/home/home.module#HomeModule"
      }, {
        path: "appointment",
        // component: AppointmentComponent,
        loadChildren:
          "./modules_/appointment/appointment.module#AppointmentModule"
      }, {
        path: "book",
        // component: AppointmentComponent,
        loadChildren:
          "./modules_/appointment/appointment.module#AppointmentModule"
      },
      {
        path: "doctor",
        loadChildren:
          "./modules_/doctor/doctor.module#DoctorModule"
      },
      {
        path: "book-appointment",
        //  component: BookAppointmentComponent,
        loadChildren:
          "./modules_/book-appointment/book-appointment.module#BookAppointmentModule"
      },
      // {
      //   path: "blog",
      //   loadChildren:
      //     "./modules_/blog/blog.module#BlogModule"
      // },
      {
        path: "contact",
        loadChildren:
          "./modules_/contact/contact.module#ContactModule"
      },
      {
        path: "about",
        loadChildren:
          "./modules_/about/about.module#AboutModule"
      },
      // {
      // path: 'teleconsultation',
	// loadChildren: "./modules_/tele-consultation/tele-consultation.module#TeleConsultationModule"
	// },
      {
        path: "payment/confirmation/:id",
        loadChildren: "./modules_/payment-confirmation/payment-confirmation.module#PaymentConfirmationModule"
      },
      {
        path: "payment/confirmation",
        loadChildren: "./modules_/capture-payment/capture-payment.module#CapturePaymentModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
