export const environment = {
  production: false,
    _VIDEO_CALL_URL: "tele.spotcare.in",
    _CONTACT_NO_: "+91 7702503636, +91 8884406335, +91 9845865700",
    _WEBGATEWAY_SOCKET_URL_: "/apinm/",
    _WEBGATEWAY_BASIC_URL_: "/apinm/",
    // _WEBGATEWAY_BASIC_URL_: "http://localhost:7011/",
    // _WEBGATEWAY_SOCKET_URL_: "http://localhost:7011/",
    _COPYRIGHT_YEAR: "2021"
};
