import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { Subscription } from 'rxjs/Subscription';
import { TimeFilterService } from './time-filter.service.component';
// import * as moment from 'moment';


 declare var moment: any;


@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.css'],
})


export class TimeFilterComponent implements OnInit, AfterViewInit {

  public dateFrom: any;
  public dateTo: any;
  public timeZone: any;
  @Input() configStyle: any;
  @Output() onDateSelect: any;

  @Output() onDateReset: any;

  // private options_ ={};

  // public dateFormat = 'MM-DD-YYYY h:mm:ss';
  public dateFormat = 'YYYY-MM-DD h:mm:ss';
  public selectedFilter: string;
  public tsSubscribers: Subscription;

  public timeInfo: any = {
    date: {
      start: moment().startOf('day'),
      end: moment().format(this.dateFormat)
    },
    timestamp: {
      start: Date.parse(moment().startOf('day')),
      end: Date.parse(moment()),
    }
  };

  public dateInputs: any = [
    {
      start: moment().subtract(12, 'month'),
      end: moment().subtract(6, 'month')
    },
    {
      start: moment().subtract(9, 'month'),
      end: moment().subtract(6, 'month')
    },
    {
      start: moment().subtract(4, 'month'),
      end: moment()
    },
    {
      start: moment(),
      end: moment().add(5, 'month'),
    }
  ];

  /**
   *  will set date range by default
   */
  public mainInput = {
    start: moment().startOf('day'),
    end: moment().endOf('day')
  };

  public singlePicker = {
    singleDatePicker: false,
    showDropdowns: false,
    opens: 'left'
  };

  public singleDate: any;
  public eventLog = '';


  // public timeInfo: any = {
  //   date: {
  //     start: moment().subtract(1, 'month').format(this.dateFormat),
  //     end: moment().format(this.dateFormat)
  //   },
  //   timestamp: {
  //     start: Date.parse(moment().subtract(1, 'month')),
  //     end: Date.parse(moment()),
  //   }
  // };


  ngOnInit() {
    this.daterangepickerOptions.settings = this.buildConfigurations();

    this.tsSubscribers = this.timeFilterService.getTimeFilterSubscriber().subscribe(timeobj => {
      this.timeInfo = timeobj;
    });

  }

  ngAfterViewInit() {

  }


  public buildConfigurations() {
    // console.log('buildConfigurations ... ' + JSON.stringify(this.configStyle));
    let config_ = {
      minDate: (this.configStyle && this.configStyle.minDate !== undefined) ?
        this.configStyle.minDate : undefined,

      maxDate: (this.configStyle && this.configStyle.maxDate !== undefined) ?
        this.configStyle.maxDate : undefined,

      startDate: this.configStyle ? this.configStyle.startDate ?
        moment(this.configStyle.startDate).toDate() : moment().startOf('day') : moment().startOf('day'),
      endDate: this.configStyle ? this.configStyle.endDate ? this.configStyle.endDate : moment().endOf('day') : moment().endOf('day'),
      locale: { format: this.dateFormat },
      alwaysShowCalendars: false,
      drops: this.configStyle ? this.configStyle.drops ? this.configStyle.drops : 'up' : 'up',
      singleDatePicker: this.configStyle ? this.configStyle.singleDatePicker ? this.configStyle.singleDatePicker : false : false,
      opens: this.configStyle ? this.configStyle.openPos ? this.configStyle.openPos : 'top' : 'top',
      timePicker: this.configStyle ? this.configStyle.timePicker != undefined ? this.configStyle.timePicker : true : true,
      ranges: this.configStyle ? this.configStyle.customRange ? this.getCustomRanges : {} : {}
    }
    // this.options_ = config_;
    // console.log(JSON.stringify(config_));
    this.mainInput.start = config_.startDate;
    return config_;
  };

  public getCustomRanges() {
    return {
      'Today': [moment().startOf('day'), moment().endOf('day')],
      'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],

      'This Month': [moment(moment().startOf('month')).startOf('day'), moment()],
      //'Last Month': [
      //  moment(moment(moment().startOf('month')).subtract(1, 'month').startOf('month')).startOf('day'),
      //  moment(moment().startOf('month')).subtract(1, 'month').endOf('month')
      //],
      'Last 3 Months': [
        moment(moment(moment().startOf('month')).subtract(3, 'month').startOf('month')).startOf('day'),
        moment(moment().startOf('month')).subtract(1, 'month').endOf('month')
      ],
      'Last 6 Months': [
        moment(moment(moment().startOf('month')).subtract(6, 'month').startOf('month')).startOf('day'),
        moment(moment().startOf('month')).subtract(1, 'month').endOf('month')
      ],
      'Last 1 Year': [
        moment(moment(moment().startOf('month')).subtract(12, 'month').startOf('month')).startOf('day'),
        moment(moment().startOf('month')).subtract(1, 'month').endOf('month')
      ],
    };
  }


  constructor(
    private daterangepickerOptions: DaterangepickerConfig,
    private timeFilterService: TimeFilterService) {
    this.singleDate = this.timeInfo.timestamp.end;
    this.onDateSelect = new EventEmitter();
    this.onDateReset = new EventEmitter();
  }

  reset() {
    this.mainInput.start = undefined;
    this.onDateReset.emit({ event: 'reset' });
  }

  public selectedDate(value: any, dateInput: any) {
    // console.log('Date selected  : ' + JSON.stringify(dateInput) + 'value : ' + JSON.stringify(value));
    this.selectedFilter = value.label;
    //console.log("start : " + this.mainInput.start + " end: " + this.mainInput.end);
    dateInput.start = value.start;
    dateInput.end = value.end;


    if (value.label == 'Today') {
      this.selectedFilter = 'td'
    } else if (value.label == 'Yesterday') {
      this.selectedFilter = 'yd'
    } else if (value.label == 'Last 7 Days') {
      this.selectedFilter = '7d'
    } else if (value.label == 'This Month') {
      this.selectedFilter = 'cm'
    } else if (value.label == 'Last 3 Months') {
      this.selectedFilter = 'l3m'
    } else if (value.label == 'Last 6 Months') {
      this.selectedFilter = 'l6m'
    } else if (value.label == 'Last 1 Year') {
      this.selectedFilter = 'cy'
    } else {
      this.selectedFilter = 'cu'
    }



    let startDateInTimeStamp = Date.parse(value.start);
    //console.log("startDateInTimeStamp", startDateInTimeStamp);
    // this.timeFilterService.changeStartDate("" + startDateInTimeStamp); // bind startDate to timeservice
    let endDateInTimeStamp = Date.parse(value.end);
    //console.log("startDateInTimeStamp", endDateInTimeStamp);
    //this.timeFilterService.changeEndDate("" + endDateInTimeStamp); // bind endDate to timeservice

    this.setDatetoTimeServices(value.start, value.end, startDateInTimeStamp, endDateInTimeStamp, this.selectedFilter);

    if (this.configStyle && this.configStyle.singleDatePicker) {
      this.onDateSelect.emit({ date: value.end.format(this.dateFormat), dateTS: endDateInTimeStamp });
    } else {
      this.onDateSelect.emit({ start: value.start, end: value.end, startTS: startDateInTimeStamp, endTS: endDateInTimeStamp });
    }


  }

  public singleSelect(value: any) {
    // console.log('singleDate.......... :' + this.singleDate);
  }

  public applyDate(value: any, dateInput: any) {
    dateInput.start = value.start;
    dateInput.end = value.end;
  }

  public calendarEventsHandler(e: any) {
    this.eventLog += '\nEvent Fired: ' + e.event.type;
  }


  public setDatetoTimeServices(dateStart, dateEnd, tsStart, tsEnd, selectedFilter) {
    //console.log("Filter :dS " + dateStart.format("DD-MM-YYYY h:mm:ss") + " dE: " + dateEnd + " tsS: " + tsStart + " tsE: " + tsEnd);
    this.timeFilterService.setTimeInfo(dateStart.format(this.dateFormat), dateEnd.format(this.dateFormat), tsStart, tsEnd, this.selectedFilter);
  }




}
